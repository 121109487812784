import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import CodeBlock from '../Markdown/CodeBlock'
import { monospace } from '../styles/variables'

// Visual components
export const Heading = styled.h2`
  margin-top: 2.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 3;
  color: inherit;
  border-bottom: 2px solid currentColor;
  letter-spacing: 2px;
  text-transform: uppercase;
`

export const Subheading = styled.h4`
  font-weight: 600;
  font-size: 0.6875rem;
  color: #a9a9a9;
  letter-spacing: 2px;
  line-height: 2.36;
  text-transform: uppercase;
  margin: 1rem 0 0.2rem;
`

export const HeaderName = styled.span`
  font-size: 0.875rem;
  font-family: ${monospace};
`

export const ResponseData = styled.p`
  font-size: 0.93rem;
  line-height: 1.75;
`

export const HttpStatusCode = styled.span`
  display: inline-block;
  padding: 0.15rem 0.55rem 0.05rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.75;
  ${({ error }) =>
    error
      ? css`
          color: #7a2f29;
          border: 1px solid #fdccc3;
          background: #fff7f5;
        `
      : css`
          color: #1a513a;
          border: 1px solid #a8e6bd;
          background: #f2fcf5;
        `}
  border-radius: 2px;

  &:before {
    content: '•';
    padding-right: 0.25rem;
    font-size: 1rem;
    line-height: 1;

    ${({ error }) =>
      error
        ? css`
            color: #7a2f29;
          `
        : css`
            color: #1a513a;
          `}
    vertical-align: text-top;
  }
`

export const ResposeSection = styled.div`
  padding: 0.2rem 0 1rem 0;
  ${({ border }) => (border ? `border-top: 2px #ddd solid;` : '')}
`

export const ParameterTable = styled.div`
  font-size: 0.75rem;
  font-family: ${monospace};
  line-height: 2;
`

// Functional components
export const Sample = ({ sample, theme }) => {
  if (!sample) {
    return null
  }

  return (
    <div>
      <Subheading>Example</Subheading>
      <CodeBlock lang="json" theme={theme}>
        {JSON.stringify(JSON.parse(sample), null, 2)}
      </CodeBlock>
    </div>
  )
}

Sample.propTypes = {
  sample: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  theme: PropTypes.object,
}

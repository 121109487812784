import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { darkGrey, pink, monospace } from '../styles/variables'
import { hex2Rgba } from '../styles/utilities'

const Wrapper = styled.code`
  display: inline-block;
  padding: 1px 4px 0;
  margin: 0 2px;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1.5;
  font-family: ${monospace};
  background: #f7f7f6;
  border: 1px solid #19191926;
  color: #3d3d3c;
  border-radius: 2px;
`

const isURL = (str) => {
  const regex =
    /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!-]))?/
  return regex.test(str)
}

const Code = ({ children, color = darkGrey, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
)

Code.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  color: PropTypes.string.isRequired,
}

export default Code

import React from 'react'
import PropTypes from 'prop-types'
import { get, has, keys, reduce } from 'lodash'

import Code from '../Markdown/Code'
import {
  HeaderName,
  Heading,
  ParameterTable,
  ResponseData,
  Sample,
  Subheading,
  HttpStatusCode,
  ResposeSection,
} from '../Operation/shared-components'
import ResponseFields from '../ResponseFields'

const getNonDefaultResponses = (responses) => {
  const codes = Object.keys(responses).filter((item) => item !== 'default')
  return codes.map((code) => ({ code, ...responses[code] }))
}

const getExampleFromResponse = (response) => {
  if (!has(response, 'content')) {
    return null
  }

  const [contentTypeSelector] = keys(get(response, 'content'))
  return get(response, `content.${contentTypeSelector}.example`)
}

const getSchemaFromResponse = (response) => {
  if (response.schema) {
    return response.schema
  }

  const [contentTypeSelector] = keys(get(response, 'content'))
  const schema = get(response, `content.${contentTypeSelector}.schema`)

  if (has(schema, 'allOf')) {
    schema.properties = reduce(
      schema.allOf,
      (acc, value) => {
        return { ...acc, ...value.properties }
      },
      {}
    )
  }

  return schema
}

const HTTPHeaders = ({ headers, color }) => {
  if (!headers || !Object.keys(headers).length) {
    return null
  }

  return (
    <div>
      <Subheading>Headers</Subheading>
      {Object.keys(headers).map((name) => {
        const header = headers[name]
        return (
          <ResponseData key={name}>
            <HeaderName>{name}</HeaderName>
            <Code color={color} style={{ margin: '0 .5rem' }}>
              {header.type}
            </Code>
            {header.description}
          </ResponseData>
        )
      })}
    </div>
  )
}

HTTPHeaders.propTypes = {
  headers: PropTypes.object,
  color: PropTypes.string,
}

const Schema = ({ schema, theme }) => {
  if (!schema || !Object.keys(schema).length) {
    return null
  }

  return (
    <div>
      <Subheading>Schema</Subheading>
      <ResponseData>{schema.description}</ResponseData>
      {schema && (
        <ParameterTable>
          <ResponseFields definition={{ schema }} theme={theme} />
        </ParameterTable>
      )}
    </div>
  )
}

Schema.propTypes = {
  schema: PropTypes.object,
  color: PropTypes.string,
}

const ResponseDetail = ({ response, theme, showBorder, defaultSample }) => {
  const { code, description, headers } = response
  const isSuccessResponse = code.startsWith('c_2')

  const sample =
    isSuccessResponse && defaultSample
      ? defaultSample
      : JSON.stringify(getExampleFromResponse(response))

  return (
    <ResposeSection border={showBorder}>
      <Subheading>Status</Subheading>
      <HttpStatusCode error={!isSuccessResponse}>
        {code.replace('c_', '')} {description}
      </HttpStatusCode>
      <HTTPHeaders headers={headers} color={theme.color} />
      <Schema schema={getSchemaFromResponse(response)} theme={theme} />
      <Sample sample={sample} theme={theme} />
    </ResposeSection>
  )
}

ResponseDetail.propTypes = {
  response: PropTypes.object,
  theme: PropTypes.object,
  defaultSample: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  showBorder: PropTypes.bool,
}

const Response = ({ operation, theme }) => {
  const responses = getNonDefaultResponses(
    JSON.parse(operation.operation.responses)
  )

  return (
    <div>
      <Heading>{responses.length === 1 ? 'Response' : 'Responses'}</Heading>
      {responses.map((response, index) => (
        <ResponseDetail
          key={response.code}
          response={response}
          theme={theme}
          showBorder={index > 0}
          defaultSample={operation.example.response}
        />
      ))}
    </div>
  )
}

Response.propTypes = {
  theme: PropTypes.object,
  operation: PropTypes.object,
  id: PropTypes.string,
}

export default Response

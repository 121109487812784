import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Page from '../components/Page'
import Operation from '../components/Operation'

class ReferencePage extends Component {
  render() {
    if (!this.props.data) {
      return null
    }

    const operation = this.props.data.content
    const category = operation.api

    return (
      <div>
        <Helmet title={operation.title} />
        <Page category={category} {...this.props}>
          <Operation operation={operation} />
        </Page>
      </div>
    )
  }
}

export default ReferencePage

export const pageQuery = graphql`
  query ReferencePagesQuery($pathname: String!) {
    content: apiDocOperation(pathname: { eq: $pathname }) {
      id
      api
      category
      method
      path
      title
      category
      example {
        request
        response
      }
      operation {
        description
        consumes
        responses
        produces
        body
        parameters {
          in
          name
          description
          required
          type
          items {
            type
          }
          schema {
            description
            example
            properties
            required
            type
            enum
          }
        }
      }
    }
  }
`

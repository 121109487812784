import React from 'react'
import styled, { css } from 'styled-components'

import { media } from '../styles/media'

export const Strong = styled.strong`
  font-weight: 600;
`

export const Paragraph = styled.p`
  font-size: 0.93rem;
  line-height: 1.75;
`

export const Note = styled(Paragraph)`
  font-size: 0.875rem;
  background: ${(props) => props.theme.note.background};
  color: ${(props) => props.theme.note.color};
  padding: 1.5rem;
  border-radius: 2px;

  strong {
    font-weight: bold;
  }
`

const baseListStyles = css`
  padding: 0 0 0 1.25rem;
  margin: 0 0 1.25rem 1.25rem;
  font-size: 0.85rem;
  line-height: 2;
`

export const OrderedList = styled.ol(baseListStyles)

export const List = styled.ul`
  list-style: disc;
  ${baseListStyles}
`

export const Image = styled.img`
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  margin: 1.5rem auto;
  ${(props) =>
    props.customCSS
      ? css`
          ${props.customCSS};
        `
      : ``};
`

export const Hr = styled.hr`
  border: 0;
  height: 1px;
  margin: 2rem 0;
  background: ${(props) => props.theme.hr};

  + span[id] h2 {
    border-top: none;
    padding-top: 0;
  }
`

const Scrollable = styled.div`
  width: 100%;
  overflow-x: auto;
`

export const Table = (props) => (
  <Scrollable>
    <TableInner {...props} />
  </Scrollable>
)

const TableInner = styled.table`
  margin: 32px 2px;
  border-style: hidden;
  border-spacing: 0;
  border-collapse: collapse;
  border-radius: 3px;
  box-shadow: 0 0 0 1px ${(props) => props.theme.table.border};
  transition: box-shadow 0.2s ease;

  ${media.tablet`
    border-right: 1px solid ${(props) => props.theme.table.border};
    border-left: 1px solid ${(props) => props.theme.table.border};
  `} th {
    font-size: 0.6875rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 600;
    background: ${(props) => props.theme.table.header};
    text-align: left;
    border-right: 1px solid ${(props) => props.theme.table.border};
    transition: 0.2s ease;
    transition-property: border, background;
  }

  th:first-child {
    border-top-left-radius: 3px;
  }

  th:last-child {
    border-top-right-radius: 3px;
  }

  tbody {
    font-size: 0.875rem;
  }

  td,
  th {
    padding: 1rem;
  }

  td {
    border: solid ${(props) => props.theme.table.border};
    border-width: 1px 1px 0 0;
    transition: 0.2s ease;
    transition-property: border, background;
  }

  tr:nth-child(even) td {
    background: ${(props) => props.theme.table.evenBackground};
  }
`

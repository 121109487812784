import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { Link as ScrollLink } from 'react-scroll'

import { darkGrey, monospace } from '../styles/variables'
import { media } from '../styles/media'

const butonStyles = css`
  display: inline-block;
  vertical-align: middle;
  padding: 0.75rem 1rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.125rem;
  box-shadow: 0 10px 30px -10px currentColor;
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  border-radius: 2px;
  cursor: pointer;

  color: ${(props) => props.color || darkGrey};
  background: ${(props) => props.background || '#fff'};
  font-family: ${(props) => (props.monospace ? monospace : 'inherit')};
  border: 1px solid ${(props) => props.borderColor || 'rgba(55, 64, 74, 0.10)'};

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 12px 50px -10px currentColor;
    text-decoration: none;
  }

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }

  ${media.tablet`
    width: calc(50% - 1rem);
    font-size: 0.8rem;

    :nth-child(2n) {
      margin-right: 0;
    }

    :nth-child(2n + 1) {
      margin-left: 0;
    }
  `};

  ${media.phone`
    width: inherit;
    padding: 13px;
    font-size: 1rem;
    margin: 0.375rem;
  `};
  @media only screen and (max-width: 340px) {
    font-size: 0.8rem;
  }
`

export const RouterButton = styled(Link)(butonStyles)

export const ScrollButton = styled(ScrollLink)(butonStyles)

export const ExternalButton = styled.a(butonStyles)
